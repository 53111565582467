<template>
  <v-col cols="12">
    <v-row>
      <v-col cols="12">
        <data-table
          :headers="headers"
          :items="obCollection.getModelList()"
          :options="pagination"
          :total="serverItemsLength"
          :last-page="serverLastPage"
          :loading="bLocalLoading"
          btn-action-path="users"
          btn-action-item-key-id="id"
          hide-btn-action-view
          @delete="deleteItem"
          @update:sort-by="onSortBy"
          @update:sort-desc="onSortDesc"
          @update:options="onPagination"
          @update:page="onPage"
        >
          <template v-slot:[`item.name`]="{ item }">
            <name-with-avatar
              :name="item.name"
              :path="item.avatar"
              :to="{ name: 'users.update', params: { id: item.id } }"
            />
          </template>

          <template v-slot:[`item.email`]="{ item }">
            <name-with-avatar
              :name="item.email"
              light
              svg-icon="mail"
              avatar-color="grey lighten-3"
              icon-color="grey"
            />
          </template>

          <template #[`item.groups`]="{ item }">
            <name-with-avatar :items="formatGroups(item.groups)" />
          </template>

          <template #[`item.companies`]="{ item }">
            <company-preview
              :companies="item.companies"
              hide-title
              v-if="item.companies.length"
            />
          </template>

          <template #[`item.is_activated`]="{ item }">
            <switch-checkmark
              @change="toggle($event, 'is_activated', item)"
              :value="item.is_activated"
            />
          </template>

          <template #[`item.actions`]="{ item }">
            <module-actions :value="item.id" @delete="deleteItem" />
          </template>
        </data-table>
      </v-col>
    </v-row>
    <users-form
      v-model="obItem"
      :open.sync="displayForm"
      :key="$route.params.id"
    />
  </v-col>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import UsersMixin from "@/modules/users/mixins/UsersMixin";
import { DataTableHeader } from "vuetify";
import { AppModule } from "@/store/app";

import ActiveIcon from "@/components/common/ActiveIcon.vue";
import NameWithAvatar, {
  NameWithAvatarItemData,
} from "@/components/common/NameWithAvatar.vue";
import UsersForm from "@/modules/users/components/Form.vue";
import CompanyPreview from "@/modules/companies/components/CompanyPreview.vue";
import SwitchCheckmark from "@/components/form/fields/SwitchCheckmark.vue";
import { User, UserData } from "@planetadeleste/vue-mc-shopaholic";

@Component({
  components: {
    ActiveIcon,
    UsersForm,
    NameWithAvatar,
    CompanyPreview,
    SwitchCheckmark,
  },
})
export default class UsersList extends Mixins(UsersMixin) {
  isList = true;

  onMounted() {
    const arHeaders: DataTableHeader[] = [
      { text: "active", value: "is_activated" },
      { text: "email", value: "email" },
    ];

    if (this.$user.get().role == "admin") {
      arHeaders.push({ text: "permissions", value: "groups" });
      arHeaders.push({ text: "companies", value: "companies" });
    }

    this.addDTHeaders(arHeaders).delDTHeader("active");

    this.index();
  }

  async toggle(bValue: boolean, sProp: string, obItemData: UserData) {
    const obModel = new User(obItemData);
    const obUserModel = this.obCollection.find({ id: obItemData.id });
    if (obUserModel) {
      obModel.set(sProp, bValue);
      obModel.store().then((obResponse) => {
        if (obResponse) {
          const obData = obResponse.getData().data;
          obUserModel.set(obData);
        }
      });
    }
  }

  formatGroups(arCodes: string[]) {
    return this.$_.map(arCodes, (sCode) => {
      const obGroup = AppModule.groups.find({ code: sCode });
      const obRole: NameWithAvatarItemData = {
        iconComp: `icon-${
          sCode == "admin" ? "shield-sharp" : "person-circle-sharp"
        }`,
        iconColor: sCode == "admin" ? "green" : "indigo",
        name: sCode,
        code: sCode,
        iconProps: { outlined: true },
        avatarColor: "grey lighten-3",
      };

      switch (sCode) {
        case "registered":
          obRole.iconColor = "amber";
          break;
        case "guest":
          obRole.iconColor = "blue-grey lighten-1";
          break;
        case "company":
          obRole.iconComp = "icon-briefcase";
          break;
        case "admin":
          this.$_.set(obRole, "iconProps.outlined", false);
          break;
      }

      if (obGroup) {
        obRole.name = obGroup.name;
      }

      return obRole;
    });
  }
}
</script>
